.btn {
  @apply inline-flex px-4 py-2 d-text-sm font-medium tracking-wider text-center justify-center items-center border-1;
  min-height: $input-height;
  min-width: $input-height;

  &.large {
    @apply px-8 py-6;
  }

  &:disabled {
    @apply bg-gray-300 opacity-50 cursor-not-allowed;
  }

  &.btn-primary {
    @apply bg-primary text-white border-primary;
  }

  &.btn-secondary {
    @apply bg-white border-primary text-primary;

    &:hover {
      @apply bg-blend-darken;
    }
  }

  &.btn-action {
    @apply bg-gray-50 rounded-full p-0 border-none d-text-lg text-gray-600;
    height: $input-height;
    width: $input-height;

    &:hover {
      @apply bg-gray-100;
    }
  }

  &.danger {
    &:hover {
      @apply text-rose-500;
    }
  }
}

.tt-layout {
  @apply flex flex-col min-h-screen;

  .tt-header {
    @apply flex items-center bg-white justify-between w-full sticky z-10 p-4 shadow-md;
  }

  .tt-content {
    @apply flex flex-1 flex-col p-4 container mx-auto;
    &.small {
      @apply w-full;
      @screen md {
        @apply w-1/2;
      }
      @screen lg {
        @apply w-2/5;
      }
    }
  }

  .tt-footer {
    @apply bg-gray-300 shadow-md text-center text-sm text-gray-500 p-4;
  }
}

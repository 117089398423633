.app-checkbox-container {
  @apply block relative cursor-pointer;

  &.disabled {
    @apply cursor-not-allowed;
  }

  padding-left: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
  }

  .checkmark {
    @apply flex absolute top-0 left-0 w-4 h-4 bg-white border-1 border-gray-500 m-auto justify-center items-center content-center;
    margin-top: 4px;

    .checkmark-icon {
      @apply hidden text-sm text-primary;
      padding-left: 1px;
    }

    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark .checkmark-icon {
    @apply block;
  }
}

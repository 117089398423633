.user-profile-accordion {
  @apply bg-white p-4 mb-4 shadow-md;
  .content {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: overlay;

    &.visible {
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    }
  }
}

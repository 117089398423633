$input-height: 38px;

$primary: #4ebf9f;

$secondary: #628ac7;

$success: #07b107;
$info: #0000ff;
$error: #aa0000;
$warning: #cd6812;

.duassis-header {
  @apply bg-white shadow-lg;
  .desktop-container {
    @apply container mx-auto px-4;
    .logo-container {
      @apply flex items-center py-4 px-2;
      img {
        @apply w-32 cursor-pointer;
      }
    }
  }

  .mobile-container {
    @apply flex flex-col absolute w-full bg-white z-50 shadow-md transition-height duration-500 ease-in-out overflow-hidden;
    -prefix-animation: 'slide 1s ease 3.5s forwards';

    &.closed {
      @apply h-0;
    }

    &.opened {
      height: calc(100vh - 100px);
    }
  }
}

.app-input {
  @apply flex appearance-none justify-center items-center px-1 py-0 w-full bg-white outline-none text-gray-800;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ced4da;
  height: 44px;
  transition: box-shadow 0.1s ease-in-out;

  &:focus {
    border-color: #9b9b9b;
  }

  &.error {
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    border-color: $error;
    border-image: initial;
  }

  &.app-input-small {
    height: 32px;
  }

  &:disabled {
    @apply cursor-not-allowed bg-gray-300;
  }
}

.app-input-label {
  @apply font-light text-sm text-primary;
  &.error {
    @apply text-error;
  }
}

.app-input-error-message {
  @apply flex text-sm text-error;
}

.app-input-success-message {
  @apply flex text-sm text-success;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import 'theme';

@import 'buttons';
@import 'calendar';
@import 'checkbox';
@import 'dropdown';
@import 'header';
@import 'input';
@import 'layout';
@import 'side_modal';
@import 'user';

* {
  font-family: 'Roboto', sans-serif;
}

#root {
  @apply bg-gray-100 min-h-screen text-gray-800;
}

.rbc-day-slot .rbc-event-label {
  @apply hidden;
}

.no-margin {
  margin: 0 !important;
}

.ReactModal__Overlay {
  z-index: 100;
  opacity: 0;
  transform: translateY(-200px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}

.ReactModal__Content {
  width: calc(100% - 20px);
}

@screen md {
  .ReactModal__Content {
    @apply w-2/3;
  }
}

@screen lg {
  .ReactModal__Content {
    @apply w-1/2;
  }
}

@screen xl {
  .ReactModal__Content {
    @apply w-1/3;
  }
}

.my-facebook-button-class {
  @apply w-full bg-white border-2 p-4 flex items-center justify-center;
  background-color: #2374e1;
  border-color: #2374e1;
  color: white;
}

.app-loading-spinner {
  @apply flex w-full m-auto justify-center items-center content-center;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($primary, 10%);
}

//Typography
.d-text-xs {
  @apply text-xs leading-xs;
}

.d-text-sm {
  @apply text-sm leading-sm;
}

.d-text-base {
  @apply text-base leading-base;
}

.d-text-lg {
  @apply text-lg leading-lg;
}

.d-text-xl {
  @apply text-xl leading-xl;
}

.d-text-2xl {
  @apply text-2xl leading-2xl;
}

.d-text-3xl {
  @apply text-3xl leading-3xl;
}

.d-text-4xl {
  @apply text-4xl leading-4xl;
}

.d-text-5xl {
  @apply text-5xl leading-5xl;
}

.d-text-6xl {
  @apply text-6xl leading-6xl;
}

.d-text-7xl {
  @apply text-7xl leading-7xl;
}

.d-text-8xl {
  @apply text-8xl leading-8xl;
}

.d-tooltip {
  @apply text-center px-3 py-1 #{!important};
  max-width: 300px;
}

//Toasters
.d-toaster {
  border-radius: 2px !important;

  .d-toaster-icon {
    @apply self-baseline mt-1;
  }

  .text-success {
    @apply text-green-500;
  }

  .text-error {
    @apply text-red-500;
  }

  .text-info {
    @apply text-blue-500;
  }
}

pre.code {
  @apply bg-gray-300 p-4 mb-4 overflow-auto;
  max-height: 50vh;

  * {
    font-family: 'Source Code Pro', monospace;
  }
}
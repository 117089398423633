.tt-calendar {
  @apply flex flex-1 flex-col;
  .tt-week {
    @apply flex flex-1 flex-row;
    .tt-day {
      @apply flex flex-1 h-24 border-1 rounded-lg border-gray-300 m-1 p-1 cursor-pointer shadow-md;
      .tt-date {
        @apply flex w-6 h-6 rounded-full border-1 text-sm text-gray-300 justify-center;
        &.this-month {
          @apply text-gray-800 border-gray-800;
        }

        &.today {
          @apply text-primary bg-white border-primary;
        }

        &.selected {
          @apply text-white bg-primary border-primary;
        }
      }

      &.over {
        @apply border-warning;
      }

      &.under {
        @apply border-success;
      }
    }
  }
}
